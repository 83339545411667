const RECORDS =  [
    'Telegram',
  /*  'Instagram',
    'YouTube',
    'Discord',*/

    'Github',
    'Twitter ',
    'Linkedin  ',
]
export default RECORDS;
